import { FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { PublicDataStore } from '~/services/public-data-service/public-data-service';
import { createCategoryService } from '../services/category-service';
import { createWidgetService, Widget } from '~/services/widget-service';
import { ReuseCategorySelection } from '~/components/CommentsWidget/panels/reuse-category.types';
import { getPublicDataStoreSnapshot } from './get-public-data-store-snapshot';
import { isDefined } from '~/ts-utils';
import { InstallProgressHandler } from './install-progress-handler';
import { BLOG_COMMENTS_APP_DEF_ID } from '~/constants/app-def-ids';

export const setWidgetCategoryId = async ({
  flowAPI,
  editorSDK,
  publicDataStore,
  installProgressHandler,
}: {
  flowAPI: FlowAPI;
  editorSDK: FlowEditorSDK;
  publicDataStore: PublicDataStore;
  installProgressHandler: InstallProgressHandler;
}) => {
  try {
    const categoryService = createCategoryService({
      httpClient: flowAPI.essentials.httpClient,
      isBlogComments: flowAPI.environment.appDefinitionId === BLOG_COMMENTS_APP_DEF_ID,
    });
    const widgetService = createWidgetService({ httpClient: flowAPI.essentials.httpClient });

    let siteWidgets: Widget[] = [];
    try {
      // queryWidgets can fail if site is not yet published
      siteWidgets = await widgetService.queryWidgets();
    } catch (e) {
      flowAPI.reportError(e instanceof Error ? e : new Error('Failed to query widgets'));
    }

    const currentPageWidgetData = await getCurrentPageWidgetData({
      editorSDK,
      widgets: siteWidgets,
    });

    const publicDataStoreSnapshot = await getPublicDataStoreSnapshot({
      editorSDK,
      flowAPI,
    });

    const usedCategoryIds = Object.values(publicDataStoreSnapshot)
      .map((store) => store?.getCategoryId())
      .filter(isDefined);

    const targetWidgetData: Widget | undefined = currentPageWidgetData[0];

    if (targetWidgetData?.categoryId && !usedCategoryIds.includes(targetWidgetData?.categoryId)) {
      const selection = await openReuseCategoryPanel(editorSDK);

      const categoryId =
        selection === 'reuse_category'
          ? targetWidgetData.categoryId
          : await categoryService.createCategory();

      categoryId && (await publicDataStore?.setCategoryId({ categoryId }));
      if (selection === 'reuse_category') {
        await editorSDK.editor.showUserActionNotification('token', {
          message: flowAPI.translations.t('editor-nofication.category-restored-success'),
          type: editorSDK.editor.NotificationType.Success,
          link: { caption: '', onClick: () => {} },
        });
      }
    } else {
      const categoryId = await categoryService.createCategory();
      categoryId &&
        (await publicDataStore?.setCategoryId({
          categoryId,
        }));
    }
    installProgressHandler.finish();
  } catch (e) {
    installProgressHandler.fail();
    console.error('Failed to set widget category id', e);
    flowAPI.reportError(
      e instanceof Error ? e : new Error('failed to create a new category during installation'),
    );
  }
};

const getCurrentPageWidgetData = async ({
  widgets,
  editorSDK,
}: {
  widgets: Widget[];
  editorSDK: FlowEditorSDK;
}) => {
  const currentPageRef = await editorSDK.pages.getCurrent('token');
  const currentPage = await editorSDK.pages.getPageData('token', {
    pageRef: currentPageRef,
  });
  const currentPath = `/${currentPage.pageUriSEO}`;

  return widgets
    .filter((widget) => {
      const hasComments = Boolean(widget.commentCount);
      const isOnCurrentPage = widget.urlPath?.relativePath === currentPath;
      const hasCompIdAsResource = widget.resourceId?.includes('comp-');

      return isOnCurrentPage && hasComments && !hasCompIdAsResource;
    })
    .sort((a, b) => (b.commentCount ?? 0) - (a.commentCount ?? 0));
};

const openReuseCategoryPanel = async (
  editorSDK: FlowEditorSDK,
): Promise<ReuseCategorySelection> => {
  const { selection } = await editorSDK.editor.openModalPanel('token', {
    url: getPanelUrl('CommentsWidget', 'reuse-category'),
    width: 510,
    height: 246,
    shouldHideHeader: true,
  });
  return selection;
};
